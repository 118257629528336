<template>
  <header
      class="flex-column flex-md-row d-flex justify-content-between align-items-center bg-dark text-light px-md-2 main-header fixed-top">
    <h1>Outils de transfert de propriété</h1>
    <div class="flex-fill small text-center mb-1 mb-md-0">
      <span class="user-info-label">Vous êtes authentifié en tant que&nbsp;:</span>
      {{ userEmail }}
    </div>
    <div class="">
      <button class="btn btn-secondary mb-2 mb-md-0" @click="signout">Se déconnecter</button>
    </div>
  </header>
</template>

<script>
import DriveApi from '../DriveApi'

export default {
  name: 'Header',
  data () {
    return {
      userEmail: DriveApi.userInfo.getEmail()
    }
  },
  methods: {
    signout () {
      DriveApi.startSignOut()
    }
  }
}
</script>

<style scoped>
.user-info-label {
  display: none;
}

@media (min-width: 768px) {
  .user-info-label {
    display: unset;
  }
}
</style>
