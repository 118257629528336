<template>
  <div class="container-md">
    <global-loader v-bind:step="step"/>
  </div>
</template>

<script>
import GlobalLoader from '../components/GlobalLoader'
import DriveApi from '../DriveApi'
import { fatalError } from '../ErrorUtility'

export default {
  name: 'Startup',
  components: { GlobalLoader },
  data () {
    return {
      step: {
        type: String,
        default: null,
        required: false
      }
    }
  },
  async created () {
    try {
      this.$data.step = 'Récupération des paramètres…'
      await DriveApi.fetchCredentials()
      this.$data.step = 'Chargement de l\'API…'
      await DriveApi.waitApiReady()
      this.$data.step = 'Initialisation de l\'API…'
      await DriveApi.initApi()
      this.$data.step = 'Authentification…'
      let event = new Event('auth:ready', {
        bubbles: true,
        cancelable: false,
      })
      this.$el.dispatchEvent(event)
    } catch (error) {
      fatalError(error, this.$router)
    }
  }
}
</script>

<style scoped>

</style>
