import Queue from './Queue'
import DriveInitWorker from './DriveInitWorker'
import UserFileWorker from './UserFileWorker'
import DriveCleanupWorker from './DriveCleanupWorker'
import { fileLocations, fileStates } from '../models/File'
import DriveFileWorker from './DriveFileWorker'

export default class QueueManager {
  constructor () {
    this._queue = new Queue()
    this._state = queueManagerStates.ready
    this._count = 0
  }

  get state () {
    return this._state
  }

  set state (value) {
    this._state = value
  }

  get doneFileCount () {
    return this._count
  }

  addFile (item) {
    if (item.state === fileStates.ready) {
      this._queue.enqueue(item)
      item.state = fileStates.pending
    }
  }

  addFiles (items) {
    items = items.filter(item => item.state === fileStates.ready)
    this._queue.enqueue(...items)
    items.forEach(item => item.state = fileStates.pending)
  }

  removeFile (item) {
    if (item.state === fileStates.pending) {
      this._queue.remove(item)
      item.state = fileStates.ready
    }
  }

  async startProcess () {
    if (this._queue.length > 0) {
      const initialState = this.state
      if (initialState === queueManagerStates.ready) {
        await this.run()
      } else if (initialState === queueManagerStates.cancelled) {
        this.state = queueManagerStates.working
      }
    }
  }

  cancelProcess () {
    const initialState = this.state
    if (initialState === queueManagerStates.working) {
      this._queue.forEach(file => file.state = fileStates.ready)
      this._queue.empty()
      this.state = queueManagerStates.cancelled
    }
  }

  async run () {
    this.state = queueManagerStates.starting
    let initWorker = new DriveInitWorker(),
      userFileWorker = new UserFileWorker(),
      driveFileWorker = new DriveFileWorker(),
      cleanupWorker = new DriveCleanupWorker(initWorker)
    await initWorker.execute()
    this.state = queueManagerStates.working
    while (this._queue.length && (this.state === queueManagerStates.working)) {
      /** @var {File} file */
      let file = this._queue.dequeue()
      if (file.state === fileStates.pending) {
        try {
          if (file.location === fileLocations.share) {
            await driveFileWorker.execute(file)
          } else {
            await userFileWorker.execute(file, initWorker.driveId)
          }
        } catch (error) {
          file.state = fileStates.error
        }
        this._count++
      }
    }
    if (this.state === queueManagerStates.working) {
      this.state = queueManagerStates.stopping
      await cleanupWorker.execute()
    }
    if (this._queue.length) {
      await this.run()
    } else {
      this.state = queueManagerStates.ready
    }
  }
}

export const queueManagerStates = {
  ready: 'ready',
  starting: 'starting',
  working: 'working',
  cancelled: 'cancelled',
  stopping: 'stopping'
}
