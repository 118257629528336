<template>
  <div class="container-md">
    <div class="row row-cols-1 row-cols-lg-3 vh-100 align-items-center">
      <div class="d-none d-lg-block"></div>
      <div class="alert alert-light border-primary" role="alert">
        <h1 class="alert-heading">Bienvenue&nbsp;!</h1>
        <p>Vous n'êtes pas authentifié. Utilisez le bouton ci-après pour vous authentifier avec votre compte Google.</p>
        <p class="text-center">
          <button ref="authButton" class="btn btn-primary" @click="startAuth" :disabled="inSignin">
            <img class="pe-2 lh-1" alt=""
                 src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zNS45NDIgMzcuNjcyQzMyLjk2MSA0MC40MDIgMjguODkxIDQyIDI0LjA0IDQyYTE4LjAzMyAxOC4wMzMgMCAwMS0xNi4xMjEtOS45MTZBMTcuOTQ4IDE3Ljk0OCAwIDAxNiAyNGMwLTIuOTA0LjY5NS01LjY1MiAxLjkxOC04LjA4MkExOC4wMzUgMTguMDM1IDAgMDEyNC4wMzkgNmM0Ljg2IDAgOC45NDUgMS43ODQgMTIuMDY4IDQuNjlsLTUuMTcyIDUuMTYyYy0xLjg3MS0xLjc4NC00LjI0OC0yLjY5Mi02Ljg5Ni0yLjY5Mi00LjY4MiAwLTguNjQ2IDMuMTQxLTEwLjA3NyA3LjM3bC0uMDE3LjA1LS4wMTcuMDUxYTEwLjc4OSAxMC43ODkgMCAwMC0uNTUgMy4zNjljMCAxLjE4OC4yMDYgMi4zNC41NjcgMy40MmwuMDE3LjA1YzEuNDMxIDQuMjMxIDUuMzk1IDcuMzcyIDEwLjA3NyA3LjM3MiAyLjQyNSAwIDQuNDkyLS42MzggNi4xMDctMS43MThsLjA0NS0uMDNjMS44ODUtMS4yNzYgMy4xNDEtMy4xNzEgMy41NTUtNS40MDRIMjQuMDR2LTYuOTYyaDE2Ljk4OWMuMjEzIDEuMTc4LjMyOCAyLjQwNi4zMjggMy42ODIgMCA1LjQ0LTEuOTM3IDEwLjAyNC01LjI5OSAxMy4xNTZsLS4wOC4wNzQtLjAzNS4wMzJ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+">Se
            connecter
          </button>
        </p>
        <div class="alert alert-dismissible" :class="{ 'alert-info': error.info, 'alert-danger': error.danger}"
             role="alert" v-if="error.active">
          <p class="mb-0">{{ error.message }}</p>
          <button type="button" class="btn-close" aria-label="Fermer" @click="closeAlert"></button>
        </div>
        <hr/>
        <div class="small alert-info alert mb-0">
          <p class="mb-0">Aucune donnée n'est enregistrée par le serveur. Toutes les opérations utilisant vos
            identifiant sont exécutées sur votre navigateur. Les échanges avec le serveur utilisent des informations
            d'identification internes. Des données, sont enregistrées dans le stockage local du navigateur afin d'aider
            au rechargement de l'application.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DriveApi from '../DriveApi'

export default {
  name: 'Login',
  data () {
    return {
      error: {
        active: false,
        message: null,
        danger: false,
        info: false
      },
      inSignin: false
    }
  },
  mounted () {
    this.$refs.authButton.focus()
  },
  methods: {
    async startAuth () {
      try {
        this.$data.inSignin = true
        await DriveApi.startSignIn()
      } catch (err) {
        if (err instanceof Error) {
          this.updateError({ message: err.message, danger: true })
        } else if (err.hasOwnProperty('error')) {
          if (err.error === 'access_denied') {
            this.updateError({ message: 'Vous n\'avez pas autorisé l\'accès.', danger: true })
          }
          if (err.error === 'popup_closed_by_user') {
            this.updateError({ message: 'Vous avez annulé l\'authentification.', info: true })
          }
        }
        this.$refs.authButton.focus()
      } finally {
        this.$data.inSignin = false
      }
    },
    closeAlert () {
      this.$refs.authButton.focus()
      this.$data.error.active = false
      this.$data.error.info = false
      this.$data.error.danger = false
    },
    updateError (newState) {
      let error = this.$data.error
      for (let name in newState) {
        if (newState.hasOwnProperty(name)) {
          error[name] = newState[name]
        }
      }
      error.active = true
    }
  }
}
</script>

<style scoped>

</style>
