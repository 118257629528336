export default class Queue {
  constructor () {
    this._queue = []
  }

  peek () {
    return this._queue.length ? this._queue[0] : undefined
  }

  enqueue (...items) {
    this._queue.push(...items)
  }

  dequeue () {
    return this._queue.length ? this._queue.shift() : undefined
  }

  remove (item) {
    let position = this._queue.indexOf(item)
    if (position >= 0) {
      return this._queue.splice(position, 1).length > 0
    }
    return false
  }

  get length () {
    return this._queue.length
  }

  empty () {
    this._queue = []
  }

  forEach (callbackfn, thisArg) {
    this._queue.forEach(callbackfn, thisArg)
  }
}
