<template>
  <div>
    <div class="position-absolute top-0 start-0 vw-100 vh-100 bg-secondary bg-gradient"></div>
    <div class="alert alert-primary position-absolute top-50 start-50 translate-middle">
      <h2 class="alert-heading">Bienvenue&nbsp;!</h2>
      <p>Cet outils permet d'effectuer le transfert de vos fichiers partagés dans les dossiers de documents de Japan
        Spirit Event vers le compte Japan Spirit Event.</p>
      <ul>
        <li>Si vous n'avez plus l'accès au dossier JSE, la liste est générée par le serveur en filtrant sur votre
          identifiant d'utilisateur.
        </li>
        <li>Si vous avez accès au dossier, la liste est générée par votre navigateur.</li>
      </ul>
      <p>La procédure vous permettra en premier de lister les documents, puis, si vous le souhaiter, de tester le
        transfert sur certains fichiers, et enfin de lancer la procédure de transfert.</p>
      <p class="text-center">
        <button class="btn btn-primary" @click="start" :disabled="clicked">Démarrer&nbsp;!</button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeMessage',
  emits: ['start'],
  data () {
    return {
      clicked: false
    }
  },
  methods: {
    start () {
      this.$data.clicked = true
      this.$emit('start')
    }
  }
}
</script>

<style scoped>
div.alert {
  width: 100vw;
}

@media (min-width: 768px) {
  div.alert {
    width: auto;
  }
}
</style>
