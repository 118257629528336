export default class File {
  /**
   * @param {gapi.client.drive.File} file
   * @param {string} parentPath
   */
  constructor (file, parentPath) {
    this._file = file
    this._parentPath = parentPath
    this._state = fileStates.ready
    this._location = file.driveId ? fileLocations.share : fileLocations.user
  }

  get id () { return this._file.id }

  get name () { return this._file.name}

  get path () { return this._parentPath + this._file.name }

  get parentName () { return this._parentPath}

  get type () { return this._file.mimeType }

  get modifiedTime () { return this._file.modifiedTime }

  get state () { return this._state }

  set state (value) { this._state = value }

  get location () { return this._location }

  set location (value) { this._location = value }

  get properties () { return this._file.appProperties }

  get parents () { return this._file.parents }

  /**
   * @param {gapi.client.drive.File} file
   */
  update (file) {
    if (file.id !== this._file.id) {
      throw Error('Invalid file update. Mismatching file id.')
    }
    let currentProps = this._file.appProperties || {},
      newProps = file.appProperties || {}
    for (let propName in newProps) {
      if (newProps.hasOwnProperty(propName)) {
        currentProps[propName] = newProps[propName]
      }
    }
    this._file.appProperties = currentProps
  }
}

export const fileStates = {
  ready: 'ready',
  pending: 'pending',
  moving: 'moving',
  done: 'done',
  error: 'error'
}

export const fileLocations = {
  share: 'share',
  user: 'user'
}
