<template>
  <router-view v-slot="{ Component }">
    <transition name="app-fade" mode="out-in">
      <component :is="Component"/>
    </transition>
  </router-view>
</template>

<script>
import DriveApi from './DriveApi'
import './main.css'

export default {
  name: 'App',
  mounted () {
    this.$el.parentNode.addEventListener('auth:ready', this.handleAuthReady)
  },
  emits: [
    'auth:ready'
  ],
  methods: {
    handleAuthReady () {
      DriveApi.addSignedInListener(this.handleSignedIn)
      this.handleSignedIn(DriveApi.isSignedIn())
    },
    handleSignedIn (isSignedIn) {
      if (isSignedIn) {
        this.$router.push('/main')
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style scoped>
p {
  text-align: center;
  font-weight: bold;
}

.app-fade-enter-active, .app-fade-leave-active {
  transition: opacity .5s ease-in-out;
}

.app-fade-enter-from, .app-fade-leave-to {
  opacity: 0;
}
</style>
