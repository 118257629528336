<template>
  <div class="container-fluid p-0 m-0 vh-100 d-flex flex-column">
    <Header/>
    <transition name="progress-fade">
      <div v-if="showProgress" class="load-progress">
        <div class="position-fixed top-0 start-0 vw-100 vh-100 bg-light bg"></div>
        <Loading :step="progressStep"
                 class="position-fixed top-50 start-50 translate-middle bg-light vw-100 w-md-auto"
                 message="Chargement de la liste des fichiers…" type="spinner-message"/>
      </div>
    </transition>
    <transition name="welcome-fade">
      <WelcomeMessage v-if="!acknowledged" class="start-message" @start="fetchFiles"/>
    </transition>
    <FileList :done-files="queueManager.doneFileCount" :files="orderedFiles"
              :finished="queueManager.doneFileCount === files.length"
              :global-state="queueManager.state" @start-all="startAll"
              @cancel-all="cancelAll" @start-item="startFile"
              @cancel-item="cancelFile"/>
  </div>
</template>

<script>
import Header from '../components/Header'
import { fatalError } from '../ErrorUtility'
import FileList from '../components/FileList'
import DriveApi, { FOLDER_MIME_TYPE } from '../DriveApi'
import WelcomeMessage from '../components/WelcomeMessage'
import Loading from '../components/Loading'
import File from '../models/File'
import QueueManager from '../worker/QueueManager'
import LocalApi from '../LocalApi'
import _ from 'lodash'

export default {
  name: 'Main',
  components: { Loading, WelcomeMessage, FileList, Header },
  data () {
    return {
      /** @var {File[]} */
      files: [],
      acknowledged: false,
      showProgress: false,
      progressStep: '',
      /** @var {QueueManager} */
      queueManager: null,
      fetchRequestCount: 0,
    }
  },
  computed: {
    orderedFiles () {
      return _.orderBy(this.files, 'path')
    }
  },
  methods: {
    async fetchFiles () {
      try {
        this.$data.acknowledged = true
        this.$data.showProgress = true
        this.$data.progressStep = 'Démarrage…'
        /** @var {gapi.auth2.BasicProfile} userInfo */
        let userInfo = await DriveApi.userInfo
        const emailAddress = userInfo.getEmail()
        await this.fetchFilesLocal(emailAddress)
        await this.fetchFilesDrive(emailAddress)
        this.$data.showProgress = false
      } catch (error) {
        fatalError(error, this.$router)
      }
    },
    updateFetchState (length) {
      let progressStep = (length > 0) ? (length + ' fichiers') : 'Pas de fichier'
      let count = this.$data.fetchRequestCount += 1
      progressStep += ' (' + count + (count > 1 ? ' requêtes)' : ' requête)')
      this.$data.progressStep = progressStep
    },
    async fetchFilesLocal (emailAddress) {
      let pageToken = null
      do {
        let result = await LocalApi.listFiles(emailAddress, pageToken)
        pageToken = result.nextPageToken
        let files = this.$data.files
        for (let file of result.files) {
          files.push(new File(file, result.parentPath))
        }
        this.updateFetchState(files.length)
      } while (pageToken !== null)
    },
    async fetchFilesDrive (emailAddress) {
      let driveId = await LocalApi.getTemporaryDrive(emailAddress)
      if (driveId !== null) {
        let files = this.$data.files, queuedFolders = [driveId]
        while (queuedFolders.length) {
          let currentFolder = queuedFolders.shift()
          for (let driveFile of await DriveApi.getFilesInFolder(currentFolder, driveId)) {
            let props = driveFile.appProperties || {}
            if (props.hasOwnProperty('originalPath')) {
              files.push(new File(driveFile, props.originalPath.length ? (props.originalPath + '/') : ''))
            }
          }
          this.updateFetchState(files.length)
        }
      }
    },
    async startFile (file) {
      this.$data.queueManager.addFile(file)
      await this.startProcess()
    },
    cancelFile (file) {
      this.$data.queueManager.removeFile(file)
    },
    async startAll () {
      this.$data.queueManager.addFiles(_.orderBy(this.$data.files, 'path'))
      await this.startProcess()
    },
    cancelAll () {
      this.$data.queueManager.cancelProcess()
    },
    async startProcess () {
      try {
        await this.$data.queueManager.startProcess()
      } catch (error) {
        fatalError(error, this.$router)
      }
    }
  },
  created () {
    this.$data.queueManager = new QueueManager()
  },
  beforeUnmount () {
    this.$data.queueManager.cancelProcess()
  }
}
</script>

<style scoped>
Header {
  z-index: 10;
}

.load-progress {
  z-index: 15;
}

.start-message {
  z-index: 5;
}

.welcome-fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.welcome-fade-leave-to {
  opacity: 0;
}

.load-progress .bg {
  opacity: .5;
}

.progress-fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.progress-fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.progress-fade-enter-from, .progress-fade-leave-to {
  opacity: 0;
}
</style>
