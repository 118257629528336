import { h, isProxy, toRaw } from 'vue'

export default {
  props: {
    item: String
  },
  render () {
    let item = this.item
    if (isProxy(item)) {
      item = toRaw(item)
    }
    if (item === null || item === '') {
      return null
    }
    let result = []
    const matchAll = item.matchAll(/[^\/]+(\/)?/g)
    for (let match of matchAll) {
      result.push(match[0])
      if (match[1] === '/') {
        result.push(h('br'))
      }
    }
    return result
  }
}
