class LocalApi {
  async getRootFolder () {
    let response = await fetch('/files/root')
    if (response.status === 500) {
      throw await response.json()
    }
    if (response.status === 200) {
      // noinspection JSUnresolvedVariable
      return (await response.json()).rootId
    }
    throw new Error('Unable to access api.')
  }

  async listFiles (emailAddress, pageToken) {
    let url = '/files?emailAddress=' + encodeURIComponent(emailAddress) +
      ((pageToken !== null) ? ('&pageToken=' + encodeURIComponent(pageToken)) : '')
    let response = await fetch(url)
    if (response.status === 500) {
      throw await response.json()
    }
    if (response.status === 200) {
      return (await response.json())
    }
    throw new Error('Unable to access api.')
  }

  async getTemporaryDrive (emailAddress) {
    let url = '/drive/' + encodeURIComponent(emailAddress)
    let response = await fetch(url, {
      method: 'GET'
    })
    if (response.status === 500) {
      throw await response.json()
    }
    if (response.status === 200) {
      // noinspection JSUnresolvedVariable
      return (await response.json()).driveId
    }
    throw new Error('Unable to access api.')
  }

  async createTemporaryDrive (emailAddress) {
    let url = '/drive/' + encodeURIComponent(emailAddress)
    let response = await fetch(url, {
      method: 'POST'
    })
    if (response.status === 500) {
      throw await response.json()
    }
    if (response.status === 200) {
      // noinspection JSUnresolvedVariable
      return (await response.json()).driveId
    }
    throw new Error('Unable to access api.')
  }

  async deleteTemporaryDrive (emailAddress) {
    let url = '/drive/' + encodeURIComponent(emailAddress)
    let response = await fetch(url, {
      method: 'DELETE'
    })
    if (response.status === 500) {
      throw await response.json()
    }
    if (response.status === 204 || response.status === 404) {
      return
    }
    throw new Error('Unable to access api.')
  }

  async moveFile (fileId, newParentId) {
    let url = '/files/' + encodeURIComponent(fileId) + '?setParent=' +
      encodeURIComponent(newParentId)
    let response = await fetch(url, {
      method: 'PATCH',
    })
    if (response.status === 500) {
      throw await response.json()
    }
    if (response.status === 200) {
      return await response.json()
    }
    throw new Error('Unable to access api.')
  }

  async createReplacementFolder(fileId) {
    let url = '/files';
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        createType: 'replacement',
        fileId: fileId
      })
    })
    if (response.status === 500) {
      throw await response.json()
    }
    if (response.status === 200) {
      return await response.json()
    }
    throw new Error('Unable to access api.')
  }

  async invalidateCache(fileId) {
    let url = '/cache/files/' + encodeURIComponent(fileId)
    let response = await fetch(url, {
      method: 'DELETE'
    })
    if (response.status === 500) {
      throw await response.json()
    }
    if (response.status === 404 || response.status === 204) {
      return
    }
    throw new Error('Unable to access api.')
  }

  async getFilePath(fileId) {
    let url = '/files/' + encodeURIComponent(fileId) + '/path'
    let response = await fetch(url)
    if (response.status === 500) {
      throw await response.json()
    }
    if (response.status === 404) {
      return null
    }
    if (response.status === 200) {
      return (await response.json()).path
    }
    throw new Error('Unable to access api.')
  }

  async getFileParent(fileId) {
    let url = '/files/' + encodeURIComponent(fileId) + '/parent'
    let response = await fetch(url)
    if (response.status === 500) {
      throw await response.json()
    }
    if (response.status === 404) {
      return null
    }
    if (response.status === 200) {
      return (await response.json())
    }
    throw new Error('Unable to access api.')
  }
}

const instance = new LocalApi()

export default instance
