import { createMemoryHistory, createRouter } from 'vue-router'
import Startup from '../views/Startup'
import FatalError from '../views/FatalError'
import NotFound from '../views/NotFound'
import Login from '../views/Login'
import Main from '../views/Main'

const routes = [
  {
    path: '/',
    name: 'Startup',
    component: Startup,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/error',
    name: 'fatal-error',
    component: FatalError,
    props: true
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFound
  }
]

const router = createRouter({
  history: createMemoryHistory(),
  routes,
})

export default router
