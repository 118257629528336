import LocalApi from '../LocalApi'
import DriveApi from '../DriveApi'

export default class DriveInitWorker {
  constructor () {
    this._driveId = undefined
  }

  get driveId () {
    return this._driveId
  }

  async execute () {
    this._driveId = await LocalApi.createTemporaryDrive(DriveApi.userInfo.getEmail())
  }
}
