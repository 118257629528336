<template>
  <div class="container-md">
    <div class="row row-cols-1 row-cols-lg-3 vh-100 align-items-center">
      <div class="d-none d-lg-block"></div>
      <div class="alert alert-danger" role="alert">
        <h2 class="alert-heading">Une erreur est survenue</h2>
        <p>{{ message }}</p>
        <p class="small" v-if="code">{{ code }}</p>
        <hr>
        <p>Veuillez rafraichir la page pour essayer de nouveau.</p>
        <div class="d-flex gap-2 justify-content-center">
          <button class="btn btn-secondary" @click="refresh">Accueil</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FatalError',
  props: {
    message: {
      type: String,
      required: true
    },
    code: {
      type: String,
      default: '',
      required: false
    }
  },
  methods: {
    refresh () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>
