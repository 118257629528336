import { fileStates } from '../models/File'
import DriveApi, { FOLDER_MIME_TYPE } from '../DriveApi'
import LocalApi from '../LocalApi'
import { toRaw } from 'vue'

/**
 * @param {File} file
 */
async function executeFolder (file) {
  file.update(await DriveApi.getFile(file.id))
  let path = file.path
  let replacement = await LocalApi.createReplacementFolder(file.id)
  let files = await DriveApi.getFilesInFolder(file.id)
  await LocalApi.invalidateCache(file.id)
  await LocalApi.invalidateCache(replacement.id)
  for (let folderFile of files) {
    /** @var {gapi.client.drive.File} updateFile */
    let updateFile = {}
    updateFile.id = folderFile.id
    updateFile.modifiedTime = folderFile.modifiedTime
    updateFile.appProperties = {
      ...folderFile.appProperties,
      originalParent: file.id,
      originalReplacement: replacement.id,
      originalPath: path
    }
    let fileWithProps = await DriveApi.updateFile(updateFile)
    let movedFile = await LocalApi.moveFile(folderFile.id, replacement.id)
  }
  await DriveApi.deleteFile(file)
}

/**
 * @param {File} file
 * @param {String} driveId
 */
async function executeFile (file, driveId) {
  file.update(await DriveApi.getFile(file.id))
  let props = toRaw(file.properties) || {}
  let newProps = { ...props }, modified = false
  if (!props.hasOwnProperty('originalParent')) {
    let parent = await LocalApi.getFileParent(file.id)
    newProps.originalParent = parent.id
    if (parent.appProperties) {
      if (parent.appProperties.hasOwnProperty('replacement')) {
        newProps.originalReplacement = parent.appProperties.replacement
      }
      if (parent.appProperties.hasOwnProperty('original')) {
        newProps.originalReplacement = newProps.originalParent
        newProps.originalParent = parent.appProperties.original
      }
    }
    modified = true
  }
  if (!props.hasOwnProperty('originalOwner')) {
    newProps.originalOwner = DriveApi.userInfo.getEmail()
    modified = true
  }
  if (!props.hasOwnProperty('originalPath')) {
    newProps.originalPath = file.parentName.replace(/\/$/, '')
    modified = true
  }
  if (modified) {
    /** @var {gapi.client.drive.File} updateFile */
    let updateFile = {}
    updateFile.id = file.id
    updateFile.modifiedTime = file.modifiedTime
    updateFile.appProperties = newProps
    file.update(await DriveApi.updateFile(updateFile))
  }
  props = file.properties
  await LocalApi.invalidateCache(file.id)
  file.update(await DriveApi.moveFile(file, driveId))
  file.update(await LocalApi.moveFile(file.id, props.hasOwnProperty('originalReplacement') ? props.originalReplacement : props.originalParent))
}

export default class UserFileWorker {
  /**
   * @param {File} file
   * @param {String} driveId
   */
  async execute (file, driveId) {
    file.state = fileStates.moving
    if (file.type === FOLDER_MIME_TYPE) {
      await executeFolder(file)
    } else {
      await executeFile(file, driveId)
    }
    file.state = fileStates.done
  }
}
