import { fileStates } from '../models/File'
import DriveApi from '../DriveApi'
import LocalApi from '../LocalApi'

export default class DriveFileWorker {
  /**
   * @param {File} file
   */
  async execute (file) {
    file.state = fileStates.moving
    file.update(await DriveApi.getFile(file.id))
    let props = file.properties || {}
    file.update(await LocalApi.moveFile(file.id, props.hasOwnProperty('originalReplacement') ? props.originalReplacement : props.originalParent))
    file.state = fileStates.done
  }
}
