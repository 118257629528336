<template>
  <tr>
    <td><Path :item="item.parentName" /><span class="fw-bold">{{ item.name }}</span></td>
    <td><Path :item="item.type" /></td>
    <td class="text-end">
      <button v-if="item.state === states.ready" class="btn btn-primary" @click="start">Transférer</button>
      <button v-if="item.state === states.pending" class="btn btn-secondary" @click="cancel">Annuler</button>
      <div v-if="item.state === states.moving" class="btn btn-info disabled">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        En cours…
      </div>
      <div v-if="item.state === states.done" class="btn btn-success disabled">Transféré.</div>
      <div v-if="item.state === states.error" class="btn btn-danger disabled">En erreur.</div>
    </td>
  </tr>
</template>

<script>
import File, { fileStates } from '../models/File'
import Path from '../components/Path'

export default {
  name: 'FileItem',
  components: { Path },
  emits: ['start', 'cancel'],
  data () {
    return {
      states: fileStates
    }
  },
  props: {
    item: File
  },
  methods: {
    start () {
      this.$emit('start', this.item)
    },
    cancel () {
      this.$emit('cancel', this.item)
    }
  }
}
</script>

<style scoped>
tr > td:nth-child(2),
tr > td:nth-child(3) {
  width: 1px;
}

@media (min-width: 768px) {
  tr > td:nth-child(2),
  tr > td:nth-child(3) {
    white-space: nowrap;
  }
  /deep/ br {
    display: none;
  }
}

td {
  vertical-align: middle;
}
</style>
