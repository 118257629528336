<template>
  <div class="d-flex align-content-center align-items-center border border-2 border-info p-2" :class="$attrs.class"
       v-if="type === 'spinner-message'">
    <div class="me-2">
      <span>{{ message }}</span>
      <span class="small text-secondary" v-if="step"><br/>{{ step }}</span>
    </div>
    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
  </div>
  <div :class="$attrs.class" v-if="type === 'spinner'">
    <div class="spinner-border" role="status"></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    type: {
      type: String,
      default: 'spinner',
      required: true
    },
    message: {
      type: String,
      default: null,
      required: false
    },
    step: {
      type: String,
      default: null,
      required: false
    },
    min: {
      type: Number,
      default: 0,
      required: false
    },
    max: {
      type: Number,
      default: 1,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
