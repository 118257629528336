<template>
  <div class="container-md">
    <div class="row row-cols-1 row-cols-lg-3 vh-100 align-items-center">
      <div class="d-none d-lg-block"></div>
      <div class="alert alert-danger" role="alert">
        <h2 class="alert-heading">
          Page non trouvée
        </h2>
        <p>L'application a tenté d'accéder à une page inexistante.</p>
        <hr/>
        <p class="small text-secondary">{{ url }}</p>
        <div class="d-flex gap-2 justify-content-center">
          <button class="btn btn-primary" @click="goBack">Retour</button>
          <button class="btn btn-secondary" @click="refresh">Rafraichir</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data () {
    return {
      url: null
    }
  },
  mounted () {
    this.$data.url = this.$route.fullPath
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    refresh () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>
