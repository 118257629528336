import DriveApi from '../DriveApi'
import LocalApi from '../LocalApi'

export default class DriveCleanupWorker {
  constructor (initWorker) {
    this._initWorker = initWorker
  }

  async execute () {
    if (!(await DriveApi.hasFiles(this._initWorker.driveId))) {
      await LocalApi.deleteTemporaryDrive(DriveApi.userInfo.getEmail())
    }
  }
}
