/**
 * Navigate to fatal error
 * @param {Object} error
 * @param router
 */
export function fatalError(error, router) {
  let target = {
    name: 'fatal-error',
    params: {
      message: 'Undefined message'
    }
  }
  if (error instanceof Error) {
    target.params.message = error.message
  } else if (error.hasOwnProperty('details')) {
    target.params.message = error.details
    target.params.code = error.code
  } else if (error.hasOwnProperty('class')) {
    target.params.message = error.title
    target.params.code = error.detail
  }
  router.push(target)
}
