const SCOPE = 'https://www.googleapis.com/auth/drive'
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest']
const FILE_LIST_FIELDS = 'id,name,mimeType,modifiedTime,parents,appProperties'
export const FOLDER_MIME_TYPE = 'application/vnd.google-apps.folder'

/**
 * Api credentials.
 * @property {String} apiKey Developer API key
 * @property {String} clientId OAuth2 client id
 */
class Credentials {
  /**
   * Builds immutable result
   * @param {Object} credentials Fetched Google API credentials
   */
  constructor (credentials) {
    Object.defineProperties(this, {
      apiKey: {
        value: credentials.apiKey,
        writable: false,
        enumerable: false,
        configurable: false
      },
      clientId: {
        value: credentials.clientId,
        writable: false,
        enumerable: false,
        configurable: false
      }
    })
  }
}

/** @var {Credentials} credentials Global credentials */
let credentials = null

const helpers = {
  /**
   * Waits for gapi object to be ready.
   * @param {Function} resolve End callback
   */
  waitApi (resolve) {
    if (typeof gapi !== 'undefined') {
      resolve()
    } else {
      setTimeout(() => helpers.waitApi(resolve), 100)
    }
  },
  /**
   * Really inits google client
   * @param {Function} resolve
   * @param {Function} reject
   */
  initClientReal (resolve, reject) {
    gapi.client.init({
      apiKey: credentials.apiKey,
      clientId: credentials.clientId,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPE,
      fetch_basic_profile: false,
    }).then(resolve, reject)
  }
}

class DriveApi {

  get userInfo () {
    return gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()
  }

  async fetchCredentials () {
    if (credentials === null) {
      credentials = new Credentials(await (await fetch('/credentials')).json())
    }
  }

  async waitApiReady () {
    await new Promise(helpers.waitApi)
  }

  async initApi () {
    await new Promise((resolve, reject) => {
      gapi.load('client:auth2', () => helpers.initClientReal(resolve, reject))
    })
  }

  addSignedInListener (listener) {
    gapi.auth2.getAuthInstance().isSignedIn.listen(listener)
  }

  isSignedIn () {
    return gapi.auth2.getAuthInstance().isSignedIn.get()
  }

  async startSignIn () {
    return await gapi.auth2.getAuthInstance().signIn({
      scope: 'email',
      prompt: 'select_account consent'
    })
  }

  startSignOut () {
    gapi.auth2.getAuthInstance().signOut()
  }

  async hasFiles (driveId) {
    let query = {
      corpora: driveId ? 'drive' : 'user',
      includeItemsFromAllDrives: !!driveId,
      pageSize: 1,
      spaces: 'drive',
      supportsAllDrives: true,
      q: 'mimeType != \'' + FOLDER_MIME_TYPE + '\'',
      fields: 'files(id)'
    }
    if (driveId) {
      query.driveId = driveId
    }
    /** @var {gapi.client.Response<gapi.client.drive.FileList>} response */
    let response = await gapi.client.drive.files.list(query)
    if (response.status !== 200) {
      throw response.result
    }
    const result = response.result
    return result.files.length
  }

  async getFilesInFolder (folderId, driveId) {
    let query = {
      spaces: 'drive',
      supportsAllDrives: true,
      orderBy: 'name',
      q: '\'' + folderId.replace(/'/g, '\\\'') + '\' in parents',
      fields: 'files(id,name,mimeType,modifiedTime,driveId,parents,appProperties),nextPageToken',
      includeItemsFromAllDrives: true
    }
    if (driveId) {
      query.driveId = driveId
      query.corpora = 'drive'
    }
    let response, files = []
    do {
      if (response) {
        query.pageToken = response.result.nextPageToken
      }
      /** @var {gapi.client.Response<gapi.client.drive.FileList>} response */
      response = await gapi.client.drive.files.list(query)
      if (response.status !== 200) {
        throw response.result
      }
      files.push(...response.result.files)
    }
    while (response.result.nextPageToken)
    return files
  }

  async deleteFile (file) {
    let query = {
      supportsAllDrives: true,
      fileId: file.id,
      resource: {
        trashed: true
      }
    }
    /** @var {gapi.client.Response<gapi.client.drive.File>} response */
    const response = await gapi.client.drive.files.update(query)
    if (response.status !== 200) {
      throw response.result
    }
  }

  async updateFile (file) {
    let query = {
      supportsAllDrives: true,
      fields: FILE_LIST_FIELDS,
      fileId: file.id,
      resource: file
    }
    delete file.id
    /** @var {gapi.client.Response<gapi.client.drive.File>} response */
    const response = await gapi.client.drive.files.update(query)
    file.id = query.fileId
    if (response.status !== 200) {
      throw response.result
    }
    return response.result
  }

  async moveFile (file, newParentId) {
    let query = {
      supportsAllDrives: true,
      fields: FILE_LIST_FIELDS,
      addParents: newParentId,
      fileId: file.id,
      resource: {
        modifiedTime: file.modifiedTime
      }
    }
    if (file.parents) {
      query.removeParents = file.parents.join(',')
    }
    /** @var {gapi.client.Response<gapi.client.drive.File>} response */
    const response = await gapi.client.drive.files.update(query)
    if (response.status !== 200) {
      throw response.result
    }
    return response.result
  }

  async getFile (fileId) {
    let query = {
      supportsAllDrives: true,
      fields: FILE_LIST_FIELDS,
      fileId: fileId
    }
    /** @var {gapi.client.Response<gapi.client.drive.File>} response */
    const response = await gapi.client.drive.files.get(query)
    if (response.status !== 200) {
      throw response.result
    }
    return response.result
  }

}

const instance = new DriveApi()

export default instance
