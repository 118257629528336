<template>
  <div class="row row-cols-1 row-cols-lg-3 vh-100 align-items-center">
    <div class="d-none d-lg-block"></div>
    <div class="d-flex align-content-center align-items-center border border-2 border-info p-2">
      <div class="me-2">
        <span>Chargement&hellip;</span>
        <span class="small text-secondary" v-if="step"><br />{{ step }}</span>
      </div>
      <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'global-loader',
  props: {
    step: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
