<template>
  <table class="table table-striped table-sticky-files" :class="{ 'table-hover': hoverActive }">
    <thead>
    <tr>
      <th>Chemin</th>
      <th>Type</th>
      <th class="text-end">
        <button v-if="globalState === globalStates.ready" :disabled="finished" class="btn btn-primary" @click="start">
          Transférer tout
        </button>
        <div v-if="globalState === globalStates.starting" class="btn btn-secondary disabled">
          <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span><br/>
          Démarrage…
        </div>
        <button v-if="globalState === globalStates.working" class="btn btn-warning" @click="cancel">
          <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span><br/>
          Interrompre
        </button>
        <div v-if="globalState === globalStates.cancelled" class="btn btn-secondary disabled">
          <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span><br/>
          Interruption…
        </div>
        <div v-if="globalState === globalStates.stopping" class="btn btn-secondary disabled">
          <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span><br/>
          Arrêt…
        </div>
      </th>
    </tr>
    <tr>
      <td class="text-small" colspan="3">
        <div class="progress">
          <div :aria-valuemax="files.length" :aria-valuenow="doneFiles" :style="progressStyle"
               aria-valuemin="0" class="progress-bar bg-success"
               role="progressbar">
            {{ doneFiles.toLocaleString() }} / {{ files.length.toLocaleString() }}
          </div>
        </div>
      </td>
    </tr>
    </thead>
    <transition-group name="file-item" tag="tbody">
      <tr v-if="files.length === 0" class="no-file">
        <td colspan="3" class="align-middle text-center bg-warning fs-4">Aucun fichier.</td>
      </tr>
      <FileItem v-for="file of files" :key="file.id" :item="file" @cancel="cancelFile" @start="startFile"/>
    </transition-group>
  </table>
</template>

<script>
import FileItem from './FileItem'
import { queueManagerStates } from '../worker/QueueManager'

export default {
  name: 'FileList',
  components: { FileItem },
  emits: ['start-item', 'cancel-item', 'start-all', 'cancel-all'],
  data () {
    return {
      globalStates: queueManagerStates
    }
  },
  computed: {
    progressStyle () {
      if (this.files.length === 0) {
        return 'width: 0'
      }
      return 'width: ' + (100 * this.doneFiles / this.files.length) + '%'
    },
    hoverActive () {
      return this.files.length > 0
    }
  },
  props: {
    files: Array,
    globalState: String,
    finished: Boolean,
    doneFiles: Number
  },
  methods: {
    startFile (item) {
      this.$emit('start-item', item)
    },
    cancelFile (item) {
      this.$emit('cancel-item', item)
    },
    start () {
      this.$emit('start-all')
    },
    cancel () {
      this.$emit('cancel-all')
    }
  }
}
</script>

<style scoped>
tr > th:nth-child(2),
tr > th:nth-child(3) {
  width: 1px;
}

@media (min-width: 768px) {
  tr > th:nth-child(2),
  tr > th:nth-child(3) {
    white-space: nowrap;
  }

  .text-end br {
    display: none;
  }
}

th {
  vertical-align: middle;
}

.file-item-enter-active, .file-item-leave-active {
  transition: opacity .5s ease-in-out;
}

.file-item-enter-from, .file-item-leave-to {
  opacity: 0;
}

.file-item-move {
  transition: transform .75s ease;
}
.no-file.file-item-leave-active {
  transition: none;
}
</style>
